<template>
    <b-container fluid>
      <b-row class="">
        <b-col class="analysis-wrapper">
            <comparisson-analysis
              :optionBar="'left'"
              :analysis="this.analysisArray[0]"
              :index="0"
              :atLeastOneVideo="this.atLeastOneVideo"
              @close="close($event)"
            ></comparisson-analysis>
        </b-col>
        <b-col class="analysis-wrapper">
          <comparisson-analysis
          :optionBar="'right'"
          :analysis="this.analysisArray[1]"
          :index="1"
          :atLeastOneVideo="this.atLeastOneVideo"
          @close="close($event)"
            ></comparisson-analysis>
        </b-col> 
      </b-row>
    </b-container>
</template>

<script>
import ComparissonAnalysis from '../components/Results/Heatmap/ComparissonAnalysis.vue';
import {  mapState } from "vuex";
export default {
  components:{
    ComparissonAnalysis
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.comparissonProjectId !== from.params.comparissonProjectId) {
      this.retrieveAnaslysis(to.params.comparissonProjectId).then(() => {
        this.$forceUpdate();
        next();
      }).catch((error) => {
        console.log(error);
        next(false); // Cancel the navigation
      });
    } else {
      next();
    }
  },
  data() {
    return {
      projectA: {},
      projectB: {},
    };
  },
  created(){
    this.projectA = this.$store.getters["project/getActiveProject"]
    this.projectB = this.$store.getters["project/getProjectToCompare"]
  },
  computed: {
    ...mapState("analysisV2",['analysisArray','guestMode']),
    atLeastOneVideo() {
        for(const analysis of this.analysisArray){
          if(analysis.data_type === "video")
            return true
        }
        return false
    },
  },
  methods:{
    close(projectId){
      const mainAnalysis = this.analysisArray.filter(item=>item.project._id!==projectId)
      this.$store.commit("analysisV2/REMOVE_PROJECT_BY_ID",projectId);
      this.$store.commit("analysisV2/SET_MAIN_ANALYSIS",mainAnalysis[0])
      this.$store.commit("project/SET_ACTIVE_PROJECT",mainAnalysis[0].project)
      this.gotoHeatmapAnalysis(mainAnalysis[0].project._id)
    },
    gotoHeatmapAnalysis(projectId){
      this.$router.push(`/en/project/${projectId}/project-analysis/`)
    },
    retrieveAnaslysis(projectId) {
      const payload = {
        projectId: projectId,
        isComparisson: true,
      };
      return this.$store
        .dispatch("analysisV2/retrieveAnalysisData", payload)
        .then((response) => {
          this.$store.commit("project/SET_PROJECT_TO_COMPARE", response.project);
        });
    },
  }
};
</script>
